import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../images/LogoHorizontalWhite-02.svg';

function Navbar({ onContactClick }) {
  const location = useLocation();

  const getLinkClass = (path) => {
    const baseClass = "py-2 px-4 border font-semibold rounded transition duration-500 ease-in-out";
    const activeClass = "bg-giants-orange text-white border-transparent";
    const inactiveClass = "bg-transparent text-white border-white hover:bg-giants-orange hover:text-white hover:border-transparent";

    return location.pathname === path ? `${baseClass} ${activeClass}` : `${baseClass} ${inactiveClass}`;
  };

  return (
    <nav className="absolute top-16 left-0 right-0 flex items-center justify-between p-4 z-40" style={{ height: '10vh' }}>
      <div className="flex items-center" style={{ marginLeft: '10%' }}>
        <Link to="/">
          <img src={Logo} alt="Glimlach Logo" style={{ height: '8vh' }} className="mr-2" />
        </Link>
      </div>
      <ul className="flex space-x-4" style={{ marginRight: '10%' }}>
        <li className="flex items-center">
          <Link className={getLinkClass('/')} to="/">Home</Link>
        </li>
        <li className="flex items-center">
          <Link className={getLinkClass('/about-us')} to="/about-us">Over ons</Link>
        </li>
        <li className="flex items-center">
          <Link className={getLinkClass('/services')} to="/services">Onze diensten</Link>
        </li>
        <li className="flex items-center">
          <button onClick={onContactClick} className={getLinkClass('/contact')}>Contact</button>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;

import React, { useRef } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import Carousel from '../components/Carousel';
import ScrollToTopButton from '../components/ScrollToTopButton';
import exampleImage from '../images/coverPage/worker.jpg'; // Replace with your actual image path
import iconBuild from '../images/coverPage/icons/iconBuild.svg'; // Replace with your actual icon paths
import iconRenovation from '../images/coverPage/icons/iconRenovation.svg';
import iconReperation from '../images/coverPage/icons/iconReparation.svg';
import ContactForm from '../components/ContactForm'; // Import the ContactForm component
import Footer from '../components/Footer'; // Import the Footer component
import Navbar from '../components/Navbar'; // Import the Navbar component

function Home() {
  const contactFormRef = useRef(null);

  const scrollToContactForm = () => {
    if (contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      <Navbar onContactClick={scrollToContactForm} />
      <Carousel scrollToContactForm={scrollToContactForm} />
      <div className="bg-white flex justify-center items-center py-16" style={{ marginTop: '-16px' }}>
        <div className="w-2/3 flex justify-between items-center">
          <div className="w-1/2 pr-8">
            <h2 className="text-3xl font-bold mb-4 text-oxford-blue">Over Glimlach Bouw & Renovatie</h2>
            <p className="text-lg leading-relaxed text-oxford-blue">
              Bij Glimlach Bouw en Renovatie zijn we trots op het leveren van topkwaliteit renovatiediensten aan onze klanten.
              Ons team van ervaren professionals is toegewijd aan het tot leven brengen van uw visie met precisie en zorg.
              Of u nu uw keuken, badkamer of uw hele huis wilt renoveren, wij hebben de vaardigheden en expertise om uw droom
              werkelijkheid te maken. Neem vandaag nog contact met ons op om aan uw volgende project te beginnen!
            </p>
          </div>
          <div className="w-1/2 pl-8 flex justify-center">
            <img src={exampleImage} alt="Glimlach Renovatie" className="object-cover" style={{ width: 'auto', height: '50vh' }} />
          </div>
        </div>
      </div>

      {/* WERKZAAMHEDEN Section */}
      <div className="bg-oxford-blue py-6">
        <h2 className="text-3xl font-bold text-center text-white">WERKZAAMHEDEN</h2>
      </div>

      {/* Blue Bar with Boxes */}
      <div className="bg-oxford-blue py-12">
        <div className="flex justify-center space-x-8">
          <div className="bg-oxford-blue w-1/5 rounded-lg p-6 shadow-inner-custom flex flex-col items-center justify-between">
            <h3 className="text-2xl font-bold text-white mb-4">Nieuwbouw/Verbouw</h3>
            <img src={iconBuild} alt="Bouwen" className="w-3/5 mb-4" />
            <Link to="/under-construction" className="border border-white text-white py-2 px-6 rounded hover:bg-giants-orange hover:border-transparent transition mt-auto">Meer weten</Link>
          </div>
          <div className="bg-oxford-blue w-1/5 rounded-lg p-6 shadow-inner-custom flex flex-col items-center justify-between">
            <h3 className="text-2xl font-bold text-white mb-4">Renovatie van A tot Z</h3>
            <img src={iconRenovation} alt="Renovatie" className="w-3/5 mb-4" />
            <Link to="/under-construction" className="border border-white text-white py-2 px-6 rounded hover:bg-giants-orange hover:border-transparent transition mt-auto">Meer weten</Link>
          </div>
          <div className="bg-oxford-blue w-1/5 rounded-lg p-6 shadow-inner-custom flex flex-col items-center justify-between">
            <h3 className="text-2xl font-bold text-white mb-4">Onderhoud/Reparatie</h3>
            <img src={iconReperation} alt="Reparatie" className="w-3/5 mb-4" />
            <Link to="/under-construction" className="border border-white text-white py-2 px-6 rounded hover:bg-giants-orange hover:border-transparent transition mt-auto">Meer weten</Link>
          </div>
        </div>
      </div>

      {/* Contact Form Section */}
      <div ref={contactFormRef}>
        <ContactForm />
      </div>

      <ScrollToTopButton />

      {/* Footer Section */}
      <Footer />
    </div>
  );
}

export default Home;

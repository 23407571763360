import React from 'react';

function ContactForm() {
  return (
    <div className="bg-white py-12">
      <div className="max-w-lg mx-auto p-8 bg-gray-100 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-6 text-oxford-blue text-center">Contact Us</h2>
        <form>
          <div className="mb-4">
            <label className="block text-oxford-blue text-sm font-bold mb-2" htmlFor="name">
              Naam
            </label>
            <input
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
              type="text"
              id="name"
              placeholder="Uw naam"
            />
          </div>
          <div className="mb-4">
            <label className="block text-oxford-blue text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
              type="email"
              id="email"
              placeholder="Uw mail"
            />
          </div>
          <div className="mb-4">
            <label className="block text-oxford-blue text-sm font-bold mb-2" htmlFor="message">
              Bericht
            </label>
            <textarea
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:shadow-outline"
              id="message"
              placeholder="Uw bericht"
              rows="5"
            ></textarea>
          </div>
          <div className="text-center">
            <button
              className="bg-giants-orange text-white py-2 px-6 rounded hover:bg-white hover:text-giants-orange border border-giants-orange transition"
              type="submit"
            >
              Stuur
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactForm;

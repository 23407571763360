import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Header from './components/Header';
import Home from './pages/Home';
import UnderConstruction from './pages/UnderConstruction'; // Importing the new UnderConstruction page

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<><Header /><Navbar /><Home /></>} />
          <Route path="/about-us" element={<UnderConstruction />} />
          <Route path="/services" element={<UnderConstruction />} />
          <Route path="/contact" element={<><Header /><Navbar /><Home /></>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

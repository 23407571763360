// src/components/Header.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  return (
    <header className="bg-oxford-blue text-white p-4 fixed w-full top-0 z-50">
      <div className="flex justify-between items-center" style={{ marginLeft: '11%', marginRight: '10%' }}>
        <div className="text-lg font-semibold flex-1 text-left flex items-center">
          <FontAwesomeIcon icon={faPhone} className="mr-2" />
          (NL) +31 6 28217263, (BE) +32 456552039
        </div>
        <div className="text-lg font-semibold flex-1 text-center flex items-center justify-center">
          <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
          info@glimlachbouw.com
        </div>
        <div className="flex-1"></div> {/* Empty div to balance the flex alignment */}
      </div>
    </header>
  );
}

export default Header;

// src/components/Carousel.js
import React, { useState, useEffect } from 'react';
import banner1 from '../images/coverPage/banner.jpg';
import banner2 from '../images/coverPage/banner2.jpg';
import banner3 from '../images/coverPage/banner3.jpg';
import banner4 from '../images/coverPage/banner4.jpg';

const Carousel = ({ scrollToContactForm }) => {
  const slides = [
    { url: banner1, alt: 'Banner 1' },
    { url: banner2, alt: 'Banner 2' },
    { url: banner3, alt: 'Banner 3' },
    { url: banner4, alt: 'Banner 4' },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000); // Set the interval to 5000 milliseconds (5 seconds)
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative w-full h-[80vh] overflow-hidden">
      <div
        className="relative w-full h-full flex transition-transform duration-1000 ease-in-out"
        style={{ transform: `translateX(-${currentSlide * 100}%)` }}
      >
        {slides.map((slide, index) => (
          <div key={index} className="flex-shrink-0 w-full h-full">
            <img src={slide.url} alt={slide.alt} className="w-full h-full object-cover" />
          </div>
        ))}
      </div>
      <div className="absolute inset-0 bg-black bg-opacity-60"></div>
      <div className="absolute inset-0 flex flex-col items-center justify-center p-8 text-left text-white">
        <div className="w-full max-w-screen-lg mx-auto">
          <h1 className="text-4xl font-bold mb-4">Ontdek de kracht van Glimlach Bouw & Renovatie!</h1>
          <p className="text-lg mb-4">Uw visie, onze passie. Geniet met een glimlach van het resultaat. Neem vandaag nog contact op!</p>
          <button onClick={scrollToContactForm} className="border border-white text-white py-2 px-4 rounded hover:bg-giants-orange hover:border-transparent transition mt-4 inline-block">
            Neem contact op
          </button>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 flex justify-center mb-4">
        {slides.map((_, index) => (
          <div
            key={index}
            className={`h-2 w-2 rounded-full mx-2 cursor-pointer transition-colors duration-300 ${
              index === currentSlide ? 'bg-blue-500' : 'bg-gray-300'
            }`}
            onClick={() => goToSlide(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;

import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-oxford-blue text-white py-6">
      <div className="container mx-auto text-center">
        <p className="text-lg">&copy; 2024 Glimlach Bouw & Renovatie. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;

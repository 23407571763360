import React from 'react';
import { useNavigate } from 'react-router-dom';

const UnderConstruction = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-oxford-blue text-white">
      <h1 className="text-4xl font-bold mb-4">Page Under Construction</h1>
      <p className="text-lg mb-8">This page is currently under construction. Please check back later.</p>
      <button
        onClick={() => navigate('/')}
        className="border border-white text-white py-2 px-4 rounded hover:bg-giants-orange hover:border-transparent transition"
      >
        Go Back
      </button>
    </div>
  );
}

export default UnderConstruction;
